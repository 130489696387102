<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="发布者">
                    <el-input v-model="queryParams.username" placeholder="请输入发布者" clearable></el-input>
                </el-form-item>
                <el-form-item label="话题">
                    <el-select v-model="queryParams.column_id" placeholder="请选择话题" clearable @change="changeQuery">
                        <el-option v-for="item in topicList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="queryParams.title" placeholder="请输入标题" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2">上线</el-option>
                        <el-option label="下线" :value="1">下线</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="queryParams.is_video" placeholder="请选择类型" clearable @change="changeQuery">
                        <el-option label="视频" :value="1">视频</el-option>
                        <el-option label="文章" :value="0">文章</el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
                <div class="action">
                    <el-button type="primary" @click="add">新增</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column label="头像" align="center" width="100" prop="avatar">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" fit="cover" :class="`img1column${scope.$index}`" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="封面图" align="center" width="100" prop="thumb">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;" fit="cover" :class="`img2column${scope.$index}`" :src="scope.row.thumb" :preview-src-list="[scope.row.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>

                </el-table-column>
                <el-table-column prop="username" label="发布者" align="center" width="140"></el-table-column>
                <el-table-column prop="title" label="标题" align="center" width="300"></el-table-column>
                <el-table-column prop="content" label="内容" align="center" width="300"></el-table-column>
                <el-table-column label="视频归属话题" align="center" width="140" prop="column_id" :formatter="mapFortopic">
                    <!-- <template scope="scope">
                        <span>{{ mapFortopic[scope.row.column_id] }}</span>
                    </template>-->
                </el-table-column>
                <el-table-column prop="ctime" label="创建时间" align="center" width="150"></el-table-column>

                <el-table-column label="类型" align="center" width="100">
                    <template slot-scope="scope" width="80">
                        <el-tag type="primary" v-if="scope.row.is_video === 1">视频</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_video === 0">文章</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否置顶" align="center" width="100">
                    <template slot-scope="scope" width="80">
                        <el-tag type="primary" v-if="scope.row.is_top === 2">是</el-tag>
                        <el-tag type="info" v-if="scope.row.is_top === 0">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否加精" align="center" width="100">
                    <template slot-scope="scope" width="80">
                        <el-tag type="primary" v-if="scope.row.is_tperfect === 2">是</el-tag>
                        <el-tag type="info" v-if="scope.row.is_tperfect === 0">否</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            topicList: [],
            options: [{
                value: '2',
                label: '可用'
            }, {
                value: '0',
                label: '不可用'
            },],
            pageInfo: {},
            value: '',
            form: {

            },
            confirmVisible: false,
            menuList: ['社区', '资源管理'],
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10
            },
        }
    },
    watch: {
        $route(to, from) {
            let ArticleEdit = from.fullPath.indexOf('/community-ArticleEdit')
            let ArticleAdd = from.fullPath.indexOf('/community-ArticleAdd')
            if (ArticleEdit !== -1 || ArticleAdd !== -1) {
                this.loadData()
            }
        }
    },
    created() {
        // console.log(this.form)
        this.getTopic()
        this.loadData()
    },
    methods: {
        add() {
            this.$router.push({ path: '/community-ArticleAdd' })
        },

        getTopic() {
            request.get('/community/column/list').then(ret => {
                if (ret.code == 1) {
                    this.topicList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        mapFortopic(row) {
            let map = []
            if (this.topicList && this.topicList.length) {
                this.topicList.forEach(item => {
                    if (item.id === row.column_id) {
                        map[item.id] = item.name
                    }
                })
            }
            return map
        },
        loadData() {
            this.loading = true;
            const _this = this
            // 获取资源管理数据
            request.get('/community/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.stime = deal.timestamp(val.stime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        edit(index, row) {
            this.title = '基本信息';
            this.current = row.id;
            this.$router.push({ path: '/community-ArticleEdit', query: { id: row.id } })
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/community/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    },
}
</script>
<style lang="scss" scoped>
.red {
    color: #ff0000;
}

.head_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 4px;
}

.pagination {
    text-align: right;
    margin-top: 10px;
}

.p-title {
    font-size: 18px;
    color: #303133;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 600;
}

.avatar {
    width: 100px;
    height: 100px;
    font-size: 28px;
    line-height: 100px;
    text-align: center;
}
</style>